
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import variables from "@/router/api";
// import Covers from "@/views/reusable/buy-insurance/motor-insurance/covers.json";

export default defineComponent({
  name: "insurance-covers",
  components: { Field, ErrorMessage },
  props: {
    widgetClasses: String,
    name: String,
    model: String,
    motorPackage: String,
    counter: Number,
    businessClass: String,
    covers: Object,
    vehiclePremiumName: String,
    vehiclePremiumModel: String,
    calculatePremium: Boolean,
  },
  data() {
    return {
      // covers: Covers,
      metadata: [
        {
          vehiclePremium: 0.0 as number,
        },
      ],
      vehiclePremium: 0.0 as number,
      vehicleValue: 0.0 as number,
      totalPremium: 0.0 as number,
      totalSumInsured: 0.0 as number,
    };
  },
  methods: {
    getMotorRate(event, position) {
      console.log(this.calculatePremium);
      if (
        this.calculatePremium != undefined &&
        this.calculatePremium === false
      ) {
        return;
      }

      // Get the rate or flat premium of the selected cover
      const rate =
        event.target.options[event.target.selectedIndex].dataset.rate;

      // Get the type of the selected cover
      const type =
        event.target.options[event.target.selectedIndex].dataset.type;

      // Get element to assign rate to
      const motorRate = document.getElementById(
        "vehicle-value-" + position
      ) as HTMLInputElement;

      // Premium placeholder
      const premium = document.getElementById(
        "vehicle-value[" + position + "]"
      ) as HTMLInputElement;

      // Vehicle value premium field
      const vehiclePremium = document.getElementById(
        "vehicle-premium-value[" + position + "]"
      ) as HTMLInputElement;

      // Check if it's rate
      if (type == "relative") {
        premium.textContent = "0.00";

        // Set vehicle value input field to null
        motorRate.value = "";

        // Attribute the rate to input property of `ariaValueNow`
        motorRate.ariaValueNow = rate;
        motorRate.accessKey =
          event.target.options[event.target.selectedIndex].value;

        // Remove readonly attribute from field
        motorRate.removeAttribute("readonly");

        // Get grand premium total
        this.totalPremium = variables.totalPremiumValue();
      }

      // Check if it's flat premium
      if (type == "absolute") {
        // Set vehicle value amd other attributes to zero
        // this.vehicleValue = rate;
        this.vehicleValue = 0;
        // const vehicleValueCal = document.getElementById(
        //   "vehicle-premium[" + position + "]1"
        // ) as HTMLInputElement;

        motorRate.ariaValueNow = rate;
        motorRate.value = 0 as any;
        // vehicleValueCal.value = 0 as any;

        // Set vehicle premium value to rate
        // vehiclePremium.value = rate;
        vehiclePremium.value = "0";
        this.vehiclePremium = rate;

        premium.textContent = rate
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.totalPremium = variables.totalPremiumValue();

        this.totalSumInsured = variables.totalSumInsured(
          0,
          "vehicle-sum-insured-value[" + position + "]"
        );

        // premium.value = rate as any;
        console.log(position, rate, type);
        this.$emit("updateVehicleValue", position, rate, type);

        // Disable field from keyup
        motorRate.setAttribute("readonly", "readonly");
      }

      this.$emit("motorCoverPublicId", event.target.value);
    },
  },
  computed: {
    mainModel() {
      return this.model;
    },
    mainVehicleName() {
      return this.vehiclePremiumName;
    },
    mainVehicleModel() {
      return this.vehiclePremiumModel;
    },
  },
});
