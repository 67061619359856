
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Form, Field } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import TotalPremiumWidget from "@/components/packages/TotalPremiumWidget.vue";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import VehicleBodyTypes from "@/views/reusable/buy-insurance/motor-insurance/vehicle-body-types.json";
import MotorInsuranceRenewalForm from "@/components/reusable/MotorInsuranceRenewalForm.vue";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "motor-insurance-renewal-form",
  components: {
    Form,
    Field,
    GoBackButton,
    TotalPremiumWidget,
    MotorInsuranceRenewalForm,
  },
  data() {
    return {
      mainDisplay: false,
      displayPMI: false,
      displayCMI: false,
      displayForm: false,
      displayError: false,
      policies: [],
      covers: [],
      underwritersList: [],
      policySummary: {} as any,
      policyDetails: [],
      totalPremium: 0.0 as number,
      insurancePackage: "",
      motorPolicyId: "",
      policy: "" as string,
      publicId: this.$route.params.publicId as string,
      premiumCalculationRoute: "" as string,
      metadatas: [],
      totalSumInsured: 0.0 as number,
      packageType: "",
      result: {
        privateCartItemPublicId: "",
        commercialCartItemPublicId: "",
        hasPrivateCartItem: true || (false as boolean),
        hasCommercialCartItem: true || (false as boolean),
      },
      vehicleBrands: [],
      bodyTypes: [] as string[],
      leadInsurerPublicId: "",
    };
  },
  setup() {
    const { findObjectByKeyValue } = ResusableFunctions();

    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();
    const store = useStore();
    const publicId = router.currentRoute.value.params.publicId;
    const userType = store.getters.currentUser;

    const metadataValidator = Yup.object().shape({
      metadata: Yup.array()
        .of(
          Yup.object().shape({
            vehicleOwner: Yup.string()
              .required()
              .label("Vehicle owner name"),
            vehicleValue: Yup.mixed()
              .when("package", {
                is: (value) => value != JwtService.getThirdPartyPM(),
                then: Yup.mixed().required(),
              })
              .label("Vehicle value"),
            package: Yup.mixed()
              .required()
              .label("Cover"),
            chassisNumber: Yup.string()
              .required()
              .min(1)
              .label("Chassis number"),
            engineNumber: Yup.string()
              .required()
              .min(1)
              .label("Engine number"),
            vehicleColor: Yup.string()
              .required()
              .label("Vehicle colour"),
            vehicleLicense: Yup.mixed()
              .label("Vehicle license")
              .optional(),
          })
        )
        .strict(),
    });

    const saveRenewal = (values, { resetForm }) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send form data
          if (JwtService.getToken()) {
            const formData = new FormData();

            for (let x = 0; x < values["metadata"].length; x++) {
              for (const [key, value] of Object.entries(
                values["metadata"][x]
              )) {
                if (Array.isArray(value)) {
                  value.forEach((itemValue, index) => {
                    formData.append(
                      `vehicleDetails[${key}][${x}][${index}]`,
                      itemValue as string | Blob
                    );
                  });
                } else {
                  formData.append(
                    `vehicleDetails[${key}][${x}]`,
                    value as string | Blob
                  );
                }
              }
            }

            formData.append("totalPremium", values["totalPremium"]);
            formData.append("totalSumInsured", values["totalSumInsured"]);
            formData.append("businessType", values["businessType"]);
            formData.append("businessClass", values["businessClass"]);

            // return new Response(formData).text().then(console.log);

            ApiService.setHeader();
            ApiService.post(
              `${variables.CART_RENEWAL_ROUTE}/edited/${publicId}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
              .then((response) => {
                store.commit(Mutations.CART_MUTATION, response.data.data.cart);
                resetForm();

                // variables.toastAlert(response.data.data.message, "success");
                variables.toastAlert(
                  "Renewal was added to cart successfully.",
                  "success"
                );

                router.push({
                  name: "insurance-packages-policy-period",
                  params: {
                    publicId: response.data.data.cartItemPublicId,
                  },
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Renewal", [
        "Insurance Packages",
        "Motor Insurance Policy",
      ]);

      MenuComponent.reinitialization();
    });

    return {
      findObjectByKeyValue,
      submitButton1,
      metadataValidator,
      userType,
      saveRenewal,
    };
  },
  created() {
    // this.hasExistingCartPackages();

    // Get vehicle policies only
    this.getPolicyInformation(this.publicId);

    // Get overs available based on the policy business type selected i.e Private or Commercial
    // this.getCovers();

    //Set page title
    document.title = "Renewal: Motor Insurance | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    hasExistingCartPackages() {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.CHECK_CART_PACKAGE_ROUTE}/MOT/renewal`)
          .then(({ data }) => {
            //Assign data to form fields
            this.result = data.data;
            this.displayCMI = data.data.hasCommercialCartItem;
            this.displayPMI = data.data.hasPrivateCartItem;

            if (this.displayCMI || this.displayPMI) {
              this.mainDisplay = true;
            }
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getPolicies() {
      //Fetch client policies
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.POLICIES_ROUTE}/business-class/MOT`)
          .then(({ data }) => {
            //Assign data to props
            if (data.data != "") {
              this.displayError = false;
              this.policies = data.data;

              if (this.publicId !== undefined && this.publicId !== null) {
                this.policy = this.publicId;
                this.getPolicyInformation(this.publicId);
              }
            } else {
              this.displayError = true;
            }
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getPolicyInformation(value) {
      this.motorPolicyId = value;
      this.premiumCalculationRoute = `${variables.CART_ROUTE}/premium/renewal/${value}`;

      //Fetch individual vehicle policy information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.CART_RENEWAL_ROUTE}/${value}`)
          .then(({ data }) => {
            //Assign data to variables
            this.policySummary = data.data.policySummary;
            this.policyDetails = data.data.items;
            this.totalPremium = data.data.policySummary.premium;
            this.metadatas = data.data.vehicleDetails;
            this.totalSumInsured = data.data.sumInsured;
            this.packageType = data.data.businessType;

            if (data.data != "") {
              const businessTypeShortName =
                data?.data?.policySummary?.businessTypeShortName;
              this.leadInsurerPublicId =
                data?.data?.policySummary?.leadInsurerPublicId;

              if (businessTypeShortName !== undefined) {
                this.insurancePackage = businessTypeShortName;
                // this.covers = data?.data?.policySummary?.packages;
                this.getVehicles(businessTypeShortName);
                this.getPackagesFromUnderwriter(businessTypeShortName);
              }

              this.displayForm = true;
            }
          })
          .catch(function(error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getCovers() {
      // let route = "";
      // if (this.insurancePackage == "PM") {
      //   route = variables.PRIVATE_MOTOR_RATE_ROUTE;
      // } else {
      //   route = variables.COMMERCIAL_MOTOR_RATE_ROUTE;
      // }

      if (JwtService.getToken()) {
        ApiService.setHeader();
        // ApiService.get(route)
        ApiService.get(
          `${variables.INSURANCE_PACKAGES_ROUTE}/${this.insurancePackage}`
        )

          .then(({ data }) => {
            //Assign data to form fields
            this.covers = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    getVehicles(type: string) {
      let route = "cars";

      if (type === "CM") {
        route = "cars";
        this.bodyTypes = VehicleBodyTypes.commercial as string[];
      }

      if (type === "PM") {
        route = "cars";
        this.bodyTypes = VehicleBodyTypes.private as string[];
      }

      if (type === "TM") {
        route = "truck";
        this.bodyTypes = VehicleBodyTypes.truck as string[];
      }

      if (type === "MC") {
        route = "motorcycle";
      }

      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.CARS_ROUTE}/${route}`)
          .then(({ data }) => {
            this.vehicleBrands = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getPackagesFromUnderwriter(type: string) {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.MOTOR_UNDERWRITERS_ROUTE}/${type}`)
          .then(({ data }) => {
            this.underwritersList = data.data;
            const underwriter = this.findObjectByKeyValue(
              this.underwritersList,
              "publicId",
              this.leadInsurerPublicId
            );

            this.covers = underwriter?.packages;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
  },
});
